<!-- Cảnh báo kiểm tra mặt vé -->
<template lang="">
  <BAlert
    variant="danger"
    show
    class="px-1 py-50"
  >
    <div>{{ $t('ticket.ticketAlertWarning.1') }}</div>
    <div>{{ $t('ticket.ticketAlertWarning.2') }}</div>
  </BAlert>
</template>
<script>
import { BAlert } from 'bootstrap-vue'

export default {
  components: { BAlert },
}
</script>
<style lang="">

</style>
